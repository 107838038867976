@import '../../../../scss/theme-bootstrap';

.split-width {
  overflow: hidden;
  text-align: $ldirection;
  &__content,
  &__content-wrapper,
  &__content-over-media,
  &__media-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
    &--image {
      max-width: 300px;
      @include breakpoint($medium-up) {
        max-width: 500px;
      }
    }
  }
  &__wrapper {
    .content-block__link-wrapper,
    .split-width__content {
      width: initial;
    }
    .split-width__content-item {
      width: 100%;
    }
    &--split-width-right {
      display: flex;
      flex-direction: column;
      @include breakpoint($landscape-up) {
        flex-direction: row;
      }
      .split-width__content-wrapper {
        @include breakpoint($landscape-up) {
          width: 50%;
          height: auto;
        }
      }
      .split-width__media-wrapper {
        @include breakpoint($landscape-up) {
          width: 50%;
        }
      }
      .split-width__content {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 130px;
        }
      }
      &--bottom {
        display: flex;
        flex-direction: column-reverse;
        @include breakpoint($landscape-up) {
          flex-direction: row;
        }
        .split-width__content-wrapper {
          @include breakpoint($landscape-up) {
            width: 50%;
            height: auto;
          }
        }
        .split-width__media-wrapper {
          @include breakpoint($landscape-up) {
            width: 50%;
          }
        }
        .split-width__content {
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 130px;
          }
        }
      }
    }
    &--split-width-left {
      display: flex;
      flex-direction: column-reverse;
      @include breakpoint($landscape-up) {
        flex-direction: row-reverse;
      }
      .split-width__content-wrapper {
        @include breakpoint($landscape-up) {
          width: 50%;
          height: initial;
        }
      }
      .split-width__media-wrapper {
        @include breakpoint($landscape-up) {
          width: 50%;
        }
      }
      .split-width__content {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 102px;
        }
      }
      &--top {
        display: flex;
        flex-direction: column;
        @include breakpoint($landscape-up) {
          flex-direction: row-reverse;
        }
        .split-width__content-wrapper {
          @include breakpoint($landscape-up) {
            width: 50%;
            height: initial;
          }
        }
        .split-width__media-wrapper {
          @include breakpoint($landscape-up) {
            width: 50%;
          }
        }
        .split-width__content {
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 102px;
          }
        }
      }
      .split-width__media--image {
        @include breakpoint($landscape-up) {
          margin: 0 auto;
        }
      }
    }
    &--border {
      border: $color-cream-stroke 1px solid;
    }
  }
  &__content {
    &-wrapper--no-padding & {
      padding: 0;
    }
  }
  .content-block__link-wrapper {
    .content-block__link-gutter {
      width: 48px;
    }
    &--new-style {
      .content-block__link-gutter {
        width: 0;
      }
    }
  }
  .content-block__line {
    padding-bottom: 23px;
    @include breakpoint($landscape-up) {
      padding-bottom: 27px;
    }
    &--eyebrow,
    &--header {
      padding-top: 32px;
      @include breakpoint($landscape-up) {
        padding-top: initial;
      }
    }
    &--eyebrow {
      padding-bottom: 43px;
    }
  }
  .content-block__link {
    padding-bottom: 44px;
    @include breakpoint($landscape-up) {
      padding-bottom: 24px;
    }
  }
}
